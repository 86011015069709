<template>
  <div>
    <Loader v-if="isCollaborationLoading" :times="2" />

    <!-- <sign-up-modal v-if="hasNewSignupFlow"/> -->
    <div v-if="collaboration && !isCollaborationLoading">
      <!-- :class="user.subscription && !thesisSlot ? 'top-margin' : ''" up -->
      <MasterHeader />
      <div class="container">
        <div class="row">
          <div class="col-12" v-if="user">
            <collaboration-link :isUserOwner="isUserOwner" />
          </div>
        </div>
        <div class="row" v-if="isLoaded">
          <div class="col-12 col-lg-9">
            <!-- status bar for both professionals and students -->
            <collaboration-status-bar
              :isUserOwner="isUserOwner"
              :isUserOffering="isUserOffering"
              :collaborationStatus="collaboration.status"
            />

            <!-- collaboration content -->
            <collaboration-text
              :collaboration="collaboration"
              :isReadMore="isReadMore"
              :isReadLess="isReadLess"
              :toggle="toggle"
              :isUserOwner="isUserOwner"
              :isEditable="isEditable"
            />

            <!-- if user is a student and there any any offers or the professional company is inviting for this thesis - just dumb comments to help reduce my stress-->
            <div
              class="widget mb-2"
              v-if="
                (isUserOwner && collaborationOffers.length > 0) ||
                (isUserOffering && false)
              "
            >
              <div class="widget-header sticky">
                <h4>Negotiations</h4>
                <div class="widget-navigation"></div>
              </div>
              <div class="widget-body">
                <!-- offers loop -->
                <div v-if="isUserOwner || isUserOffering">
                  <!-- student side -->
                  <template
                    v-if="isUserOwner && collaborationOffers.length > 0"
                  >
                    <div
                      class="erow row d-flex highlighted"
                      v-for="(offer, index) in collaborationOffers"
                      :key="index"
                    >
                      <div class="negotations-company-header p-2">
                        <!--image  -->
                        <!-- comment-f1 -->
                        <div
                          v-if="
                            offer.companyDetails.imageUrl && offer.status !== 2
                          "
                          class="image-container my-2 align-self-center"
                        >
                          <img
                            :src="offer.companyDetails.imageUrl"
                            alt="comapanyLogo"
                            class=""
                          />
                        </div>
                        <div class="d-flex">
                          <div
                            class="flex-grow-1"
                            v-if="offer.companyDetails.imageUrl"
                          >
                            <div
                              v-if="offer.companyDetails.industry"
                              class="company-industry-section"
                            >
                              <h4>
                                {{ offer.companyDetails.companyName }}
                              </h4>
                              <p v-if="offer.status !== 2">
                                {{ offer.companyDetails.industry.name }}
                              </p>
                            </div>
                            <!-- comment-f1 -->
                            <div
                              v-if="
                                offer.companyDetails.companyDescription &&
                                offer.status !== 2
                              "
                              class="company-description mb-4"
                            >
                              <h6>Description</h6>
                              <p>
                                {{ offer.companyDetails.companyDescription }}
                              </p>
                            </div>
                            <div
                              v-if="
                                offer.companyDetails.companyURL &&
                                offer.status !== 2
                              "
                              class=""
                              style="color: #0a7380"
                            >
                              <p>{{ offer.companyDetails.companyURL }}</p>

                              <p v-if="offer.companyDetails.companyLinkedinUrl">
                                {{ offer.companyDetails.companyLinkedinUrl }}
                              </p>
                            </div>
                            <p class="text-capitalize">
                              <span
                                >{{
                                  offer.companyDetails.billingStreetAddress
                                }},</span
                              >
                              <span>
                                {{ offer.companyDetails.billingPostCode }}
                                {{ offer.companyDetails.billingCity }},</span
                              >
                              <span>
                                {{ offer.companyDetails.billingCountry }}</span
                              >
                            </p>
                          </div>
                          <div
                            v-if="!offer.companyDetails.imageUrl"
                            class="align-self-center flex-grow-1"
                            style="line-height: 1.5rem"
                          >
                            <p
                              class="negotations-company-title text-capitalize"
                            >
                              {{ offer.companyDetails.companyName }}
                            </p>
                            <p class="negotations-company-address mb-2">
                              {{ offer.companyDetails.billingStreetAddress }},
                              {{ offer.companyDetails.billingPostCode }}
                              {{ offer.companyDetails.billingCity }},
                              {{ offer.companyDetails.billingCountry }}
                            </p>
                          </div>
                          <div class="text-center">
                            <!-- this is student content when status is 2 and thus we are waiting on the company action -->
                            <button
                              type="button"
                              @click="
                                changeOfferStatues(offer, offer.status + 1)
                              "
                              :disabled="!offer.arrangedInterview"
                              class="btn btn-primary btn-sm text-white"
                              v-if="offer.status === 2"
                            >
                              Confirm collaborationz
                            </button>
                            <br />
                            <button
                              type="button"
                              @click="changeOfferStatues(offer, -2)"
                              class="btn btn-link-purple btn-sm p-0 mt-2"
                              v-if="offer.status > 1 && offer.status < 3"
                            >
                              Decline invitation
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- status -->
                      <div class="col-12 offer-details">
                        <div class="row">
                          <div
                            class="negotations-status-student"
                            v-if="offer.status === 1"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              You need to accept or decline the invitation.
                            </div>
                          </div>
                          <div
                            class="negotations-status-student"
                            v-if="offer.status === 2"
                            :class="
                              offer.status === 2 && offer.arrangedInterview
                                ? ''
                                : 'grey-bg'
                            "
                          >
                            <div
                              class="negotations-status-title"
                              :class="
                                offer.status === 2 && offer.arrangedInterview
                                  ? ''
                                  : 'text-dark'
                              "
                            >
                              Status
                            </div>
                            <div
                              class="negotations-status-sub-title"
                              :class="
                                offer.status === 2 && offer.arrangedInterview
                                  ? ''
                                  : 'text-dark'
                              "
                              v-if="!offer.arrangedInterview"
                            >
                              <!-- You need to confirm or decline the collaboration -->
                              Waiting on the company to contact you in regards
                              to arranging the interview.
                              <br />
                            </div>
                            <div
                              class="negotations-status-sub-title"
                              :class="
                                offer.status === 2 && offer.arrangedInterview
                                  ? ''
                                  : 'text-dark'
                              "
                              v-if="offer.arrangedInterview"
                            >
                              You need to confirm or decline the collaboration
                            </div>
                          </div>
                          <div
                            class="negotations-status"
                            v-if="offer.status === 3"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              waiting on
                              {{ offer.companyDetails.companyName }} to accept
                              or decline the collaboration.
                            </div>
                          </div>
                          <div
                            v-if="offer.status === 4"
                            class="negotations-status-student"
                          >
                            <div class="confirmed-status">Confirmed!</div>
                          </div>
                          <div
                            class="negotations-status-student"
                            v-if="offer.status === 0"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              Cancelled by company
                            </div>
                          </div>
                          <div
                            class="negotations-status-student"
                            v-if="offer.status === -1 || offer.status === -2"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              Declined by students
                            </div>
                          </div>
                        </div>
                        <!--progress bar  -->
                        <div class="col-12 offer-steps" v-if="offer.status > 0">
                          <ul>
                            <li class="activated" title="Step 1 description">
                              Invitation
                              <div class="step"></div>
                            </li>
                            <li
                              :class="
                                offer.status === 2 || offer.status > 2
                                  ? 'activated'
                                  : ''
                              "
                              title="Step 2 description"
                            >
                              Interview
                              <div class="step"></div>
                            </li>
                            <li
                              :class="
                                offer.status === 3 || offer.status > 3
                                  ? 'activated'
                                  : ''
                              "
                              title="Step 3 description"
                            >
                              Confirmation
                              <div class="step"></div>
                            </li>
                            <li
                              :class="offer.status === 4 ? 'activated' : ''"
                              title="Step 4 description"
                            >
                              Collaboration
                              <div class="step"></div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="row negotations-request">
                        <div class="col-6">
                          <div v-if="offer.createdDate">
                            <p class="negotations-request-title">
                              Interview request received
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(offer.createdDate.seconds).format(
                                  "DD-MM-Y"
                                )
                              }}
                            </p>
                          </div>
                          <!--??? which one is this -->
                          <div
                            v-if="
                              (offer.status === 2 || offer.status > 2) &&
                              offer.acceptedByStudentAt
                            "
                          >
                            <p class="negotations-request-title">
                              Invitation accepted by students
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(
                                  offer.acceptedByStudentAt.seconds
                                ).format("DD-MM-Y")
                              }}
                            </p>
                            <p
                              class="negotations-request-title"
                              v-if="offer.arrangedInterview"
                            >
                              Interview Arranged
                            </p>
                            <p
                              class="negotations-request-sub-title"
                              v-if="offer.arrangedInterview"
                            >
                              {{ offer.arrangedInterview }}
                            </p>
                          </div>
                          <div
                            v-if="
                              (offer.status === 3 || offer.status > 3) &&
                              offer.confirmedByStudentAt
                            "
                          >
                            <p class="negotations-request-title">
                              Collaboration confirmed by students
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(
                                  offer.confirmedByStudentAt.seconds
                                ).format("DD-MM-Y")
                              }}
                            </p>
                          </div>
                          <div
                            v-if="
                              (offer.status === 4 || offer.status > 4) &&
                              offer.confirmedByCompanyAt
                            "
                          >
                            <p class="negotations-request-title">
                              Collaboration confirmed by the company
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(
                                  offer.confirmedByCompanyAt.seconds
                                ).format("DD-MM-Y")
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="col-6">
                          <p class="negotations-request-title">
                            Message from company
                          </p>
                          <p class="negotations-request-sub-title">
                            {{ offer.offerDetails.offerDescription }}
                          </p>
                        </div>
                      </div>
                      <!-- comment-f1 -->
                      <div
                        class="negotations-company-header p-4"
                        v-if="offer.status !== 2"
                      >
                        <div class="negotations-buttons">
                          <button
                            type="button"
                            @click="changeOfferStatues(offer, offer.status + 1)"
                            class="btn btn-primary btn-sm"
                            v-if="offer.status === 1"
                          >
                            Accept invitation
                          </button>
                          <button
                            type="button"
                            class="btn btn-light btn-sm text-white"
                            v-if="offer.status === 3"
                          >
                            Waiting for company..
                          </button>

                          <button
                            type="button"
                            @click="changeOfferStatues(offer, -1)"
                            class="btn-link-purple btn btn-sm p-0 mt-2"
                            v-if="offer.status > 0 && offer.status < 2"
                          >
                            Decline invitation
                          </button>
                          <button
                            type="button"
                            @click="changeOfferStatues(offer, -1)"
                            class="btn btn-link-purple btn-sm p-0 mt-2"
                            v-if="offer.status === 3"
                          >
                            Cancel Collaboration
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>

                  <!-- professional side -->
                  <!-- fix this 1 -->
                  <template v-if="isUserOffering">
                    <div
                      class="erow row d-flex"
                      v-for="(offer, index) in usersOffer"
                      :key="index"
                    >
                      <!-- comment-f1 -->
                      <div class="negotations-company-header p-2">
                        <div
                          v-if="
                            offer.companyDetails.imageUrl && offer.status !== 2
                          "
                          class="image-container mt-2 mb-3 align-self-center"
                        >
                          <img
                            :src="offer.companyDetails.imageUrl"
                            alt="ComapanyLogo"
                            class=""
                          />
                        </div>
                        <div v-if="offer.companyDetails.imageUrl">
                          <div
                            v-if="
                              offer.companyDetails.industry &&
                              offer.status !== 2
                            "
                            class="company-industry-section"
                          >
                            <h4 class="header-margin">
                              {{ offer.companyDetails.companyName }}
                            </h4>
                            <!-- comment-f1 -->
                            <p class="mb-0" v-if="offer.status !== 2">
                              {{ offer.companyDetails.industry.name }}
                            </p>
                          </div>
                          <!-- comment-f1 -->
                          <div
                            v-if="
                              offer.companyDetails.companyDescription &&
                              offer.status !== 2
                            "
                            class="company-description"
                          >
                            <h6 class="header-margin">Description</h6>
                            <p class="mb-0">
                              {{ offer.companyDetails.companyDescription }}
                            </p>
                          </div>
                          <!-- comment-f1 -->
                          <div
                            v-if="
                              offer.companyDetails.companyURL &&
                              offer.status !== 2
                            "
                            class=""
                            style="color: #0a7380"
                          >
                            <p class="header-margin">
                              {{ offer.companyDetails.companyURL }}
                            </p>

                            <p
                              v-if="offer.companyDetails.companyLinkedinUrl"
                              class="mb-0"
                            >
                              {{ offer.companyDetails.companyLinkedinUrl }}
                            </p>
                          </div>

                          <p
                            class="text-capitalize header-margin"
                            v-if="offer.status !== 2"
                          >
                            <span
                              >{{
                                offer.companyDetails.billingStreetAddress
                              }},</span
                            >
                            <span>
                              {{ offer.companyDetails.billingPostCode }}
                              {{ offer.companyDetails.billingCity }},</span
                            >
                            <span>
                              {{ offer.companyDetails.billingCountry }}</span
                            >
                          </p>
                        </div>
                        <div
                          v-if="offer.status === 2 && user.subscription"
                          class="d-flex justify-content-between"
                        >
                          <div
                            class="align-self-center"
                            style="line-height: 1.5rem"
                          >
                            <p
                              class="negotations-company-title text-center text-capitalize"
                            >
                              {{ offer.companyDetails.companyName }}
                            </p>
                            <p
                              class="negotations-company-address mb-2 text-center"
                            >
                              {{ offer.companyDetails.billingStreetAddress }},
                              {{ offer.companyDetails.billingPostCode }}
                              {{ offer.companyDetails.billingCity }},
                              {{ offer.companyDetails.billingCountry }}
                            </p>
                          </div>
                          <div class="">
                            <div
                              v-if="
                                offer.status === 2 &&
                                user.subscription &&
                                !offer.arrangedInterview
                              "
                              class="negotations-company-header p-4"
                            >
                              <div class="negotations-buttons">
                                <button
                                  type="button"
                                  class="btn btn-primary btn-block px-4 text-white"
                                  :disabled="!thesisSlot"
                                  @click="
                                    updateCollaborationArrangedInterview(
                                      offer.collaborationId
                                    )
                                  "
                                >
                                  Confirm Arranged Interview
                                </button>
                                <button
                                  type="button"
                                  @click="changeOfferStatues(offer, 0)"
                                  class="btn btn-link-purple btn-sm p-0 mt-2"
                                >
                                  Cancel invitation
                                </button>
                              </div>
                            </div>
                            <div
                              v-if="
                                offer.status !== 3 &&
                                offer.status < 3 &&
                                offer.arrangedInterview
                              "
                              class="negotations-company-header p-4"
                            >
                              <div class="negotations-buttons">
                                <button
                                  type="button"
                                  class="btn btn-light btn-block px-4 text-white"
                                >
                                  Confirm collaboration
                                </button>
                                <button
                                  type="button"
                                  @click="changeOfferStatues(offer, 0)"
                                  class="btn btn-link-purple btn-sm p-0 mt-2"
                                >
                                  Cancel invitation
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- status -->
                      <div class="col-12 offer-details">
                        <div class="row">
                          <div
                            class="negotations-status"
                            v-if="offer.status === 1"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              Waiting on students to accept or decline
                              invitation.
                            </div>
                          </div>
                          <div
                            class="negotations-status pink-bg"
                            v-if="offer.status === 2"
                          >
                            <div class="negotations-status-title text-white">
                              Status
                            </div>
                            <div
                              class="negotations-status-sub-title text-white"
                              v-if="!user.subscription"
                            >
                              Students have accepted the interview request. You
                              need to select a subscription plan to get their
                              contact details to proceed with the collaboration.
                              <br />
                              <!-- <p class="my-2">
                                After the interview, students will confirm or
                                decline the collaboration from their side.
                              </p> -->
                            </div>
                            <div
                              class="negotations-status-sub-title text-white"
                              v-if="
                                user.subscription && !offer.arrangedInterview
                              "
                            >
                              Students have accepted the interview request. Here
                              is their contact information:
                              <br />
                              <br />
                              {{ collaborationMembers }}
                              <!--todo add member emails here {{ collaborationMembers[0].members.email }} -->
                              <br />
                              <br />
                              REMEMBER to confirm the interview once it has been
                              arranged
                            </div>
                            <div
                              class="negotations-status-sub-title text-white"
                              v-if="
                                user.subscription && offer.arrangedInterview
                              "
                            >
                              The students need to confirm or decline the
                              collaboration
                              <br />
                              They are expected to do so after the interview
                            </div>
                          </div>
                          <div
                            class="negotations-status-student"
                            v-if="offer.status === 3"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              You need to confirm or decline the collaboration
                            </div>
                          </div>
                          <div
                            class="negotations-status-student"
                            v-if="offer.status === 4"
                          >
                            <div class="confirmed-status">Confirmed!</div>
                          </div>
                          <div
                            class="negotations-status"
                            v-if="offer.status === 0"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              Cancelled by company
                            </div>
                          </div>
                          <div
                            class="negotations-status"
                            v-if="offer.status === -1 || offer.status === -2"
                          >
                            <div class="negotations-status-title">Status</div>
                            <div class="negotations-status-sub-title">
                              Declined by students
                            </div>
                          </div>

                          <div
                            class="col-12 col-lg-4 align-self-center text-right"
                            v-if="offer.status === 3"
                          ></div>
                        </div>
                        <div class="col-12 offer-steps" v-if="offer.status > 0">
                          <ul>
                            <li class="activated" title="Step 1 description">
                              Invitation
                              <div class="step"></div>
                            </li>
                            <li
                              :class="
                                offer.status === 2 || offer.status > 2
                                  ? 'activated'
                                  : ''
                              "
                              title="Step 2 description"
                            >
                              Interview
                              <div class="step"></div>
                            </li>
                            <li
                              :class="
                                offer.status === 3 || offer.status > 3
                                  ? 'activated'
                                  : ''
                              "
                              title="Step 3 description"
                            >
                              Confirmation
                              <div class="step"></div>
                            </li>
                            <li
                              :class="offer.status === 4 ? 'activated' : ''"
                              title="Step 4 description"
                            >
                              Collaboration
                              <div class="step"></div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="row negotations-request">
                        <div class="col-5">
                          <div v-if="offer.createdDate">
                            <p class="negotations-request-title">
                              Interview request sent
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(offer.createdDate.seconds).format(
                                  "DD-MM-Y"
                                )
                              }}
                            </p>
                          </div>
                          <div
                            v-if="
                              (offer.status === 2 || offer.status > 2) &&
                              offer.acceptedByStudentAt
                            "
                          >
                            <p class="negotations-request-title">
                              Invitation accepted by students
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(
                                  offer.acceptedByStudentAt.seconds
                                ).format("DD-MM-Y")
                              }}
                            </p>
                          </div>
                          <!-- comment-f invitation date -->
                          <div
                            v-if="
                              (offer.status === 2 || offer.status > 2) &&
                              offer.arrangedInterview
                            "
                          >
                            <p class="negotations-request-title">
                              Interview Arranged
                            </p>
                            <p class="negotations-request-sub-title">
                              {{ offer.arrangedInterview }}
                            </p>
                          </div>
                          <div
                            v-if="
                              (offer.status === 3 || offer.status > 3) &&
                              offer.confirmedByStudentAt
                            "
                          >
                            <p class="negotations-request-title">
                              Collaboration confirmed by students
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(
                                  offer.confirmedByStudentAt.seconds
                                ).format("DD-MM-Y")
                              }}
                            </p>
                          </div>
                          <div
                            v-if="
                              (offer.status === 4 || offer.status > 4) &&
                              offer.confirmedByCompanyAt
                            "
                          >
                            <p class="negotations-request-title">
                              Collaboration confirmed by the company
                            </p>
                            <p class="negotations-request-sub-title">
                              {{
                                moment(
                                  offer.confirmedByCompanyAt.seconds
                                ).format("DD-MM-Y")
                              }}
                            </p>
                          </div>
                        </div>

                        <div class="col-7">
                          <p class="negotations-request-title">
                            Message to students
                          </p>
                          <p class="negotations-request-sub-title">
                            {{ offer.offerDetails.offerDescription }}
                          </p>
                        </div>
                      </div>
                      <!-- comment-f : don't show if status is 2 as well | when exactly do we show this? -->
                      <div
                        v-if="
                          offer.status !== 3 &&
                          offer.status < 3 &&
                          offer.status !== 2
                        "
                        class="negotations-company-header p-4"
                      >
                        <div class="negotations-buttons">
                          <button
                            type="button"
                            class="btn btn-light btn-block px-4 text-white"
                          >
                            Confirm collaboration
                          </button>
                          <button
                            type="button"
                            @click="changeOfferStatues(offer, 0)"
                            class="btn btn-link-purple btn-sm p-0 mt-2"
                          >
                            Cancel invitation
                          </button>
                        </div>
                      </div>
                      <div
                        class="row negotations-request"
                        :class="user.subscription ? 'bg-yellow' : ''"
                        style="color: black"
                        v-if="offer.status === 3"
                      >
                        <div
                          class="col-lg-8"
                          :class="user.subscription ? 'col-lg-12' : ''"
                        >
                          <p class="negotations-request-title">Order details</p>
                          <p v-if="user.subscription">
                            Please be aware: Collaborating with this thesis
                            group requires you to upgrade your subscription
                            <br />
                            as you have 0 available thesis slots for the
                            {{
                              collaboration.targetSemester
                                ? collaboration.targetSemester
                                : "n/a"
                            }}
                          </p>
                          <br />
                          <p v-if="user.subscription">
                            Collaboration is not possible without upgrading.
                          </p>
                          <p class="pt-2" v-if="user.subscription && true">
                            Thank you for being a part of our
                            {{ user.subscriptionPlan }} subscription.
                          </p>
                          <p class="pt-2" v-if="user.subscription && true">
                            Communication from now on with the student should
                            take place outside of the Excelerate platform. We
                            hope the collaboration will prove to be fruitful for
                            both you and the student.
                          </p>
                          <p
                            class="negotations-status-title"
                            v-if="user.subscription && true"
                          >
                            By confirming the collaboration you will allocate 1
                            thesis slot from your subscription plan to this
                            collaboration until the end date of the thesis
                            collaboration
                          </p>
                          <div
                            class="form-group"
                            v-if="user.subscription && true"
                          >
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="offerTerms"
                                v-model="terms"
                                value="terms"
                                required
                              />
                              <label
                                class="custom-control-label"
                                style="color: black"
                                for="offerTerms"
                                >By checking this, you accept our
                                <router-link to="/company/terms" target="_blank"
                                  ><u>terms and conditions </u></router-link
                                >
                                for collaborations and subscriptions</label
                              >
                            </div>
                          </div>
                          <div
                            class=""
                            style="text-align: center"
                            v-if="user.subscription"
                          >
                            <button type="button" class="btn btn-primary px-5">
                              Upgrade now
                            </button>
                            <br />
                            <button
                              type="button"
                              class="btn btn-link-purple btn-sm p-0"
                            >
                              Cancel collaboration
                            </button>
                          </div>
                        </div>
                        <div
                          class="col-lg-4"
                          style="text-align: center"
                          v-if="user.subscription && true"
                        >
                          <button
                            type="button"
                            class="btn btn-primary btn-block"
                            :disabled="!terms || !thesisSlot"
                            @click="companyAcceptCollaboration(offer)"
                          >
                            Confirm collaboration
                          </button>
                          <button
                            type="button"
                            @click="changeOfferStatues(offer, 0)"
                            class="btn btn-link-purple btn-sm p-0 mt-3"
                          >
                            Cancel invitation
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <!-- step 5 end -->

            <!-- step 6 -->
            <div class="widget mb-3">
              <div class="widget-header sticky">
                <h4 id="members">Members</h4>
                <div class="widget-navigation">
                  <span
                    class="widget-nav"
                    data-toggle="modal"
                    @click="clearInvitationData"
                    data-target="#collabInvite"
                    v-if="isUserOwner && collaborationOffers.length === 0"
                    ><i class="fas fa-paper-plane"></i> Invite team member</span
                  >
                </div>
              </div>
              <div class="widget-body">
                <!-- step 7 -->
                <div v-if="collaborationInvites.length > 0">
                  <!-- the invites need to be an array to work this way -->
                  <!-- invites -->
                  <UsersInvites
                    :collaborationInvites="collaborationInvites"
                    :user="user"
                    :isUserOwner="isUserOwner"
                    @refresh="handleRefresh"
                  >
                  </UsersInvites>
                </div>
                <!-- members -->
                <Member
                  :profile="member"
                  :id="member.id"
                  :isUserOwner="isUserOwner"
                  :user="user"
                  :isUserOfferingViewingAproved="isUserOfferingViewingAproved"
                  v-for="(member, index) in collaborationMemberData"
                  :key="index"
                  :index="index"
                  :remove="true"
                  @removeMember="rmMember"
                ></Member>
              </div>
            </div>
            <div
              class="modal fade"
              id="collabInvite"
              tabindex="-1"
              role="dialog"
              aria-labelledby="collabInvite"
              aria-hidden="true"
              v-if="isUserOwner"
            >
              <div
                class="modal-dialog modal-dialog-centered modal-md"
                role="document"
              >
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="collabInvite">
                      Invite team member
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form @submit.prevent="sendInvitationOnCollaboration()">
                      <div class="form-row">
                        <div class="col-12 col-lg-8">
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Enter email to invite"
                            v-model="inviteEmail"
                            required
                          />
                        </div>
                        <div class="col-12 col-lg-4">
                          <button class="btn btn-primary btn-block">
                            Invite
                          </button>
                        </div>
                      </div>
                      <div class="alert alert-danger mt-2" v-if="inviteError">
                        {{ inviteError }}
                      </div>
                      <div
                        class="alert alert-success mt-2"
                        v-if="inviteMessage"
                      >
                        {{ inviteMessage }}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- Members -->
          </div>
          <!-- start -->
          <div class="col-12 col-lg-3">
            <div class="collaboration-sidebar">
              <div
                class="collaboration-details"
                v-if="collaboration.institution"
              >
                <img
                  :src="
                    '/assets/institutions/' +
                    collaboration.institution.id +
                    '.png'
                  "
                  class="img-fluid"
                />
                <h3>Project information</h3>

                <h4>Project type</h4>
                <p>Thesis project</p>

                <h4 class="mt-3" v-show="collaboration.targetSemester">
                  Collaboration semester
                </h4>
                {{
                  collaboration.targetSemester
                    ? collaboration.targetSemester
                    : "n/a"
                }}
                <template
                  v-if="
                    collaboration.thesisHandInDate &&
                    collaboration.lastStartDate
                  "
                >
                  <h4 class="mt-3">Collaboration duration</h4>
                  {{
                    diff_weeks(
                      collaboration.thesisHandInDate,
                      collaboration.lastStartDate
                    )
                  }}
                  to
                  {{
                    diff_weeks(
                      collaboration.thesisHandInDate,
                      collaboration.firstStartDate
                    )
                  }}
                  weeks
                </template>

                <template v-if="collaboration.firstStartDate">
                  <h4 class="mt-3">Earliest collaboration start date</h4>
                  {{
                    $moment(collaboration.firstStartDate).format("DD.MM.YYYY")
                  }}
                </template>
                <!-- <h4 class="mt-3" v-show="collaboration.lastStartDate">
                  Collaboration end date
                </h4>
                {{ $moment(collaboration.lastStartDate).format("DD.MM.YYYY") }} -->

                <h4 class="mt-3" v-show="collaboration.members">Number of students</h4>
                <template v-if="collaboration.members">
                  {{ collaboration.members.length }}
                </template>

                <h4 class="mt-3">Collaboration language</h4>
                <span
                  v-for="(lang, index) in collaboration.languages"
                  :key="index"
                >
                  {{ lang
                  }}<template
                    v-if="
                      collaboration.languages.length > 0 &&
                      index + 1 !== collaboration.languages.length
                    "
                    >,
                  </template>
                </span>
                <!-- <h4>Project assignment language</h4> -->

                <!-- <h6 v-if="collaboration.institution">University</h6>
                {{ collaboration.institution.name ? collaboration.institution.name : null  }} -->

                <div class="mt-4" v-if="collaboration.substatus !== 'ghost'">
                  <div
                    class="collaboration-action"
                    v-if="
                      (userRole === 'professional' &&
                        user.company &&
                        !!user.subscription &&
                        !isUserOffering &&
                        collaboration.status !== 'collaborating') ||
                      (!isUserOfferingNegotiating &&
                        userRole === 'professional' &&
                        user.company &&
                        !!user.subscription &&
                        collaboration.status !== 'collaborating')
                    "
                  >
                    <h6>Do you see the potential in this group?</h6>
                    <p>Then request an interview with the group</p>

                    <!-- https://github.com/vuejs/vue-router/pull/2098 issue open with vuejs vue router. -->
                    <router-link
                      :disabled="!thesisSlot"
                      :event="thesisSlot ? 'click' : ''"
                      :style="
                        !thesisSlot
                          ? 'cursor: default; color: black; background-color: #FBFBFB; border: 1px solid #E1E1E1'
                          : ''
                      "
                      :to="'/offer/' + $route.params.id"
                      class="btn btn-primary btn-md btn-action"
                    >
                      Request Interview
                    </router-link>
                  </div>
                  <div
                    class="collaboration-action"
                    v-if="
                      userRole === 'professional' &&
                      user.company &&
                      isUserOffering &&
                      isUserOfferingNegotiating &&
                      userRole === 'professional' &&
                      user.company
                    "
                  >
                    <h6>Do you see the potential in this group?</h6>
                    <p>Then request an interview with the group</p>

                    <button
                      type="button"
                      class="btn btn-light btn-md btn-invited text-white"
                    >
                      Already Invited
                    </button>
                  </div>

                  <div
                    v-if="
                      userRole === 'professional' &&
                      !user.company &&
                      collaboration.status !== 'collaborating'
                    "
                  >
                    <button
                      class="btn btn-primary btn-lg"
                      @click="handleRegistration"
                    >
                      Register Company<br />
                      <small>To start collaborating</small>
                    </button>
                  </div>
                  <div
                    v-if="
                      userRole === 'professional' &&
                      user.company &&
                      !user.subscription &&
                      collaboration.status !== 'collaborating'
                    "
                  >
                    <button
                      class="btn btn-primary btn-lg"
                      @click="handleSubscription"
                    >
                      Select Subscription Plan<br />
                      <small>To start collaborating</small>
                    </button>
                    <!-- <router-link
                      to="/subscription"
                      class="btn btn-primary btn-lg"                      
                    >
                      Select Subscription Plan<br />
                      <small>To start collaborating</small>
                    </router-link>                     -->
                  </div>
                  <!--  to register with excelerate -->
                  <div v-if="!user.id">
                    <router-link to="/register" class="btn btn-primary btn-lg">
                      Create a profile <br />
                      <small>To start collaborating</small>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end -->
          <div
            v-if="user.subscription && !thesisSlot"
            :class="
              user.subscription && !thesisSlot
                ? 'no-thesis-banner text-center pt-4 fixed-top'
                : ''
            "
          >
            <p class="d-inline">
              Please be aware: Collaborating with this thesis group requires you
              to upgrade your subscription as you have 0 available thesis slots
              for the
              {{
                collaboration.targetSemester
                  ? collaboration.targetSemester
                  : "n/a"
              }}
            </p>
            <router-link
              to="/subscription"
              class="btn btn-link-purple btn-sm px-5 d-inline"
            >
              Upgrade now
            </router-link>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-12 mt-4">
            <Loader :times="2"></Loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import MasterHeader from "@/components/MasterHeader.vue";
import Member from "@/components/Member.vue";
import UsersInvites from "@/components/UsersInvites.vue";
import Loader from "@/components/Loader.vue";
import SignUpModal from "../../components/signUpModal.vue";
import CollaborationLink from "../../components/collaboration/collaborationLink.vue";
import CollaborationStatusBar from "../../components/collaboration/collaborationStatusBar.vue";
import CollaborationText from "../../components/collaboration/collaborationText.vue";
import { base64ToString } from "../../utils/utils";
import collaborationService from "../../services/collaborationService";

// const userType = localStorage.getItem('i_am')
// const userVerified = localStorage.getItem('u-verified')
// const role = localStorage.getItem('i_am')
let userType = base64ToString(localStorage.getItem("i_am"));
let userVerified = localStorage.getItem("u-verified");
let role = base64ToString(localStorage.getItem("i_am"));
let userId = base64ToString(localStorage.getItem("uid"));

// import { mapState } from 'vuex'
export default {
  name: "view-public-proposal",

  data() {
    return {
      terms: false,
      inviteEmail: "",
      inviteError: "",
      inviteMessage: "",
      thesisSlot: true,
      matchPrice: 349,
      // collaboration: {
      //   createdDate: "",
      //   institution: {
      //     //todo skeleton img!
      //     name: "",
      //     id: "undefined",
      //   },
      // },
      collaborationsMemberList: [],
      newMemberEmail: "",
      data: {
        collapsedHeight: 350,
        readMore: "Read More",
        readLess: "Read Less",
        speed: 200,
      },
      isReadMore: true,
      isReadLess: false,
      isPageLoaded: this.isLoaded,
      collaboration: {},
      collaborationInvites: {},
      collaborationOffers: {},
      isCollaborationLoading: true,
    };
  },
  components: {
    MasterHeader,
    Member,
    UsersInvites,
    Loader,
    CollaborationLink,
    CollaborationStatusBar,
    CollaborationText,
    //SignUpModal,
  },
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "noindex, nofollow", // Adding noindex and nofollow directives
      },
    ],
  },
  // firestore() {
  //   return {
  //     collaboration: db.collection("collaborations").doc(this.$route.params.id),
  //   };
  // },
  created() {
    const collaborationId = this.$route.params.id;

    collaborationService
      .getById(collaborationId)
      .then(({ thesis, invites, offers, interests }) => {
        this.collaboration = thesis;
        this.collaborationInvites = invites;
        this.collaborationOffers = offers;
        this.isCollaborationLoading = false;
      })
      .catch(() => this.$router.push("/collaborations").catch(() => {}));
    if (userType === "student" && !this.$store.getters.studentData) {
      this.$store.dispatch("getStudentData", userId);
    } else if (
      userType === "professional" &&
      !this.$store.getters.professionalData
    ) {
      this.$store.dispatch("getProfessionalData", userId);
    }

    if (userVerified == 0 && userType == "student") {
      this.$router.push("/user/student-welcome");
    }
    if (userVerified == 0 && userType == "professional") {
      this.$router.push("/user/welcome");
    }
  },
  computed: {
    isLoaded: function () {
      return !this.$store.getters.collaborationIsLoading;
    },
    userRole() {
      return role;
    },
    user() {
      if (role === "professional") return this.$store.getters.professionalData;
      if (role === "student") return this.$store.getters.studentData;
      return null;
    },
    isUserOwner: function () {
      return this.collaboration.members.includes(this.user?.email);
    },
    collaborationMembers: function () {
      // console.log(this.collaboration.membersData);
      return this.collaboration.members;
    },
    collaborationMemberData: function () {
      // console.log(this.collaboration.membersData);
      return this.collaboration.membersData;
    },
    desiredOccupation: function () {
      var result = [];
      // if (typeof this.collaborationMembers != "undefined") {
      //   this.collaborationMembers.forEach((cm) => {
      //     // console.log(cm.members.jobs);
      //     if (typeof cm.members.jobs != "undefined") {
      //       result = result.concat(cm.members.jobs);
      //     }
      //   });
      // }
      // result = result.map((res) =>
      //   res.name == "All"
      //     ? res.catgeory_name + " ( " + res.name + " )"
      //     : res.name
      // );

      // result = result.filter((v, i, a) => a.indexOf(v) === i);
      // result.sort(function (a, b) {
      //   return a.localeCompare(b);
      // });
      return result;
    },
    isUserOffering: function () {
      var result = true;
      const companyId = this.$store.getters.professionalData?.company;
      // const collaborationOffers = this.collaboration.offers
      //is users companyId matching any of the offering companies' id and is the user a pro role.
      // if(collaborationOffers && this.userRole === 'professional') {
      // }
      // if (
      //   typeof undefined !== typeof companyOffers &&
      //   this.userRole === "professional"
      // ) {
      //   companyOffers.forEach(function (offer, index) {
      //     // result =
      //     //   offer.companyId === userCompanyId ||
      //     //   (offer.companyId === userCompanyId &&
      //     //     offer.status !== 0)
      //     //     ? true
      //     //     : true;
      //     if (
      //       offer.companyId === userCompanyId ||
      //       (offer.companyId === userCompanyId &&
      //         offer.status !== 0)
      //     ) {
      //       result = true;
      //     }
      //   });
      // }
      return result;
    },
    isUserOfferingNegotiating: function () {
      var vm = this;
      var result = false;
      var userCompanyId = this.user.company;
      var companyOffers = this.collaborationOffers;
      var activeNegotiations = [];
      //is users companyId matching any of the offering companies' id and is the user a pro role.
      if (
        typeof undefined !== typeof companyOffers &&
        this.userRole === "professional"
      ) {
        companyOffers.forEach(function (offer, index) {
          //skyd mig
          if (offer.companyId === userCompanyId && offer.status >= 1) {
            activeNegotiations.push("1");
          }
        });
        if (activeNegotiations.length > 0) {
          result = true;
        }
      }

      return true;
    },
    isUserOfferingViewingAproved: function () {
      var result = false;
      var userCompanyId = this.user.company;
      var companyOffers = this.collaborationOffers;
      //is users companyId matching any of the offering companies' id and is the user a pro role.
      if (
        typeof undefined !== typeof companyOffers &&
        this.userRole === "professional"
      ) {
        companyOffers.forEach(function (offer, index) {
          result =
            offer.companyId === userCompanyId && offer.status >= 3
              ? true
              : false;
        });
      }
      return result;
    },
    usersOffer: function () {
      const collaborationOffers = this.$store.getters.collaborationOffers;
      var userCompanyId = "Bx875ueZoFPMpRbK7hIo";
      var result = {};

      if (
        typeof undefined !== typeof collaborationOffers &&
        this.userRole === "professional"
      ) {
        result = collaborationOffers.filter((item) => {
          return item.companyId === userCompanyId;
        });
      }
      return result;
    },
    isEditable: function () {
      // var result = this.collaborationOffers;
      // result = result.filter((res) => res.offer.status > 0);
      // return result.length > 0 ? false : true;
      return true;
    },
    userCompany: function () {
      return this.$store.getters.company;
    },
    subscriptionId: function () {
      return this.$store.getters.newUserSubscriptionId;
    },
  },
  methods: {
    getCollaboration() {
      this.isCollaborationLoading = true;
      const collaborationId = this.$route.params.id;
      collaborationService
        .getById(collaborationId)
        .then(({ thesis, invites, offers, interests }) => {
          this.collaboration = thesis;
          this.collaborationInvites = invites;
          this.collaborationOffers = offers;
          this.isCollaborationLoading = false;
        });
    },
    handleRefresh() {
      this.getCollaboration();
    },
    clearInvitationData() {
      this.inviteEmail = "";
      this.inviteError = "";
      this.inviteMessage = "";
    },
    handleSubscription() {
      this.user.selectedCollabByCompany = this.$route.params.id;
      // console.log(this.user.selectedCollabByCompany)
      this.$router.push("/subscription/");
    },
    handleRegistration() {
      this.user.selectedCollabByCompany = this.$route.params.id;
      this.$router.push("/company/new");
    },
    loadPageMeta() {
      // var members = this.collaboration.members;
      // if (typeof members !== typeof undefined) {
      //   // console.log('dbhcjsf')
      //   this.$store.dispatch(
      //     "getCollaborationMembers",
      //     this.collaboration.members
      //   );
      //   this.$store.dispatch(
      //     "getCollaborationInvitesByCollabId",
      //     this.$route.params.id
      //   );
      //   this.$store.dispatch(
      //     "getCollaborationOffersCollabId",
      //     this.$route.params.id
      //   );
      // }
    },
    rmMember(member) {
      const collaborationId = this.$route.params.id;
      const data = {
        targetEmail: member,
        collaborationId,
      };
      collaborationService.removeThesisMember(data).then(() => {
        this.isCollaborationLoading = true;
        this.getCollaboration();
      });
      // var upmc = this.collaboration.members.filter((cm) => cm != member);
      // this.$store.dispatch("updateCollaborationMembers", {
      //   collaborationId: this.$route.params.id,
      //   members: upmc,
      // });
      // this.$store.dispatch(
      //   "getCollaborationMembers",
      //   this.collaboration.members
      // );
      // var updatedCollaborationMember = this.collaborationMembers.map(cm=>cm.id);
    },
    sendInvitationOnCollaboration() {
      const vm = this;

      var data = {
        collaborationId: vm.$route.params.id,
        collaborationTitle: vm.collaboration.title,
        sender: `${this.user.firstName} ${this.user.lastName}`,
        senderEmail: vm.user.email,
        targetEmail: [vm.inviteEmail],
      };
      this.inviteMessage = "";
      this.inviteError = "";
      collaborationService
        .addThesisInvite(data)
        .then((res) => {
          this.collaborationInvites.push(res[0]);
          this.$forceUpdate();
          this.inviteMessage = "user invited successfully";
        })
        .catch((error) => {
          this.inviteError = error.response.data.message;
        });
      // vm.inviteError = "";
      // vm.inviteMessage = "";k
      // todo
      // a - user is an existing student, then we send the invitation - use template: Student - Invitation Recieved, already a user
      // b - user is a professional, then we don't send the invitation
      // c - user is not registered, then we send the invitation - use template: Student - Invitation Recieved, not a user
      // d - user is already invited - just show the message to the user
    },

    changeStatus(newStatus) {
      const data = {
        id: this.$route.params.id,
        newStatus: newStatus,
        user: this.user,
        redirect: false,
      };
      this.$store.dispatch("updateCollaborationStatus", data);
    },

    acceptInvitation(invite) {
      var vm = this;
      var data = {
        collaborationId: this.$route.params.id,
        inviteId: invite.id,
        existingMembers: this.collaboration.members,
        interests: this.user.interests,
      };
      vm.$store.dispatch("acceptCollaborationInvitation", data);

      //MAIL 'Student - New Student Member Joined'
      const templateData = {
        toEmail: invite.invites.senderEmail,
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        param1: vm.collaboration.title,
        param2: vm.$route.params.id,
        template: "Student - New Student Member Joined",
      };
      vm.$store.dispatch("sendMail", templateData);

      // this.$store.dispatch('getCollaborationInvitesByCollabId', this.$route.params.id);
    },
    declineInvitation(invite) {
      const data = {
        collabId: this.$route.params.id,
        inviteId: invite.id,
      };
      // if(invite.invites.targetEmail == this.user.email){
      const templateData = {
        toEmail: invite.invites.targetEmail,
        param1: this.user.firstName,
        template: "Student - Declined invitation",
      };
      this.$store.dispatch("sendMail", templateData);
      // }
      // console.log(invite);
      this.$store.dispatch("deleteCollaborationInviteById", data);
      // this.$store.dispatch('getCollaborationInvitesByCollabId', this.$route.params.id); // deleteCollaborationInviteById does this now..
    },
    removeMember(user) {
      // ! no idea why this exists
      // console.log("🚀 ~ file: ViewCollaborations.vue:1345 ~ removeMember ~ user:", user)
      var vm = this;
      // var existingMembers = this.collaboration.members;
      // var membersLeft = existingMembers.filter((member) => {
      //   return member !== user.id;
      // });
      // var data = {
      //   collaborationId: this.$route.params.id,
      //   members: membersLeft,
      // };
      // collaborationService.removeThesisMember()
      // MAIL 'Student - Removed team member' @param1: firstName (receiver), param2: collaboration.title
    },
    // setting a date for the arranged interview
    updateCollaborationArrangedInterview: function () {
      var vm = this;
      var today = moment();
      var arrangedInterview = today.format("DD-MM-Y");
      var data = {
        collaborationId: this.$route.params.id,
        arrangedInterview: arrangedInterview,
        status: 2,
      };
      this.$store.dispatch("updateOfferStatus", data);
    },
    changeOfferStatues: function (offer, status) {
      var vm = this;
      // var existingMembers = this.collaborationMembers;
      // console.log(offer, status);

      //1 - new invitation
      //2 . students confirm
      //0 - company cancels
      //-1 student cancels interview invitation
      //-2 - student cancels
      //the order in the if statements are a mess - I know ;)
      const data = {
        collaborationId: this.$route.params.id,
        status,
      };
      var today = moment();
      //step 2
      if (status === 3) {
        if (
          confirm(
            "We hope you had a great interview! \n\nYou are accepting on behalf of the whole group.\nDo you wish to continue?"
          )
        ) {
          this.$store.dispatch("updateOfferStatus", {
            ...data,
            confirmedByStudentAt: today.format("DD-MM-Y"),
          });
          // alert('3')
          //MAIL 'Student - Collaboration confirmed' -> TO EVERY MEMBERS @firstName (receiver - student firstName), param1: collaboration.title, param2: company.name
          // existingMembers.forEach(function (member, index) {
          //   const templateData = {
          //     toEmail: member.members.email,
          //     firstName: member.members.firstName,
          //     param1: vm.collaboration.title,
          //     param2: offer.companyDetails.companyName,
          //     template: "Student - Collaboration confirmed by students",
          //   };
          //   vm.$store.dispatch("sendMail", templateData);
          // });

          //MAIL 'professional - Collaboration confirmed by students' -> TO COMPANY USER @param1: firstName (receiver - company firstName), param1: collaboration.title
          // const templateData = {
          //   toEmail: offer.user.email,
          //   firstName: offer.user.firstName,
          //   param1: vm.collaboration.title,
          //   param2: vm.$route.params.id,
          //   template: "professional - Collaboration confirmed by students",
          // };
          // vm.$store.dispatch("sendMail", templateData);

          // dispatch();
        }
      } else if (status === 2) {
        this.$store.dispatch("updateOfferStatus", data);
        //MAIL 'Student - Meeting request Accepted' -> TO EVERY MEMBERS @param1: firstName (receiver - student firstName), param1: collaboration.title, param2: company.name, param3: company.user.firstName, param4: company.user.lastName, param5: company.user.email, param6: company.user.phoneNumber
        // existingMembers.forEach(function (member, index) {
        //   const templateData = {
        //     toEmail: member.members.email,
        //     firstName: member.members.firstName,
        //     param1: vm.collaboration.title,
        //     param2: offer.companyDetails.companyName,
        //     param3: offer.user.firstName,
        //     param4: offer.user.lastName,
        //     param5: offer.user.email,
        //     param6: offer.user.phoneNumber,
        //     param7: vm.$route.params.id,
        //     template: "Student - Meeting request Accepted",
        //   };
        //   vm.$store.dispatch("sendMail", templateData);
        // });

        //TODO: MAIL 'professional - Meeting request Accepted' -> TO COMPANY USER @param1: firstName (receiver - company user firstName), param2: collaboration.title
        // const templateData = {
        //   toEmail: offer.user.email,
        //   firstName: offer.user.firstName,
        //   param1: vm.collaboration.title,
        //   param2: vm.$route.params.id,
        //   template: "professional - Meeting request Accepted",
        // };
        // vm.$store.dispatch("sendMail", templateData);

        // dispatch();
      } else if (status === 4) {
        // dispatch();
        // alert("4");
      } else if (status === -2) {
        /**
         * Fired : When students decline a collaboration
         * @student
         * Template : Student - Collaboration declined by students
         * @professional
         * Template : professional - Collaboration declined by students
         */
        if (
          confirm(
            "This will withdraw the invitation!\nDo you wish to continue?"
          )
        ) {
          // existingMembers.forEach(function (member, index) {
          //   const templateData = {
          //     toEmail: member.members.email,
          //     firstName: member.members.firstName,
          //     param1: offer.companyDetails.companyName,
          //     param2: vm.collaboration.title,
          //     // param3: vm.$route.params.id,
          //     template: "Student - Collaboration declined by students",
          //   };
          //   vm.$store.dispatch("sendMail", templateData);
          // });

          //TODO: MAIL 'professional - Meeting request declined' -> TO COMPANY USER @firstName (receiver - company user firstName), param1: collaboration.title
          // const templateData = {
          //   toEmail: offer.user.email,
          //   firstName: offer.user.firstName,
          //   param1: vm.collaboration.title,
          //   template: "professional - Collaboration declined by students",
          // };
          // vm.$store.dispatch("sendMail", templateData);
          // dispatch();
          // alert("-2");
        }
      } else if (status === -1) {
        // console.log(offer, offer.status);
        if (confirm("Decline invitation?\nDo you wish to continue?")) {
          //TODO: MAIL 'Student - Meeting request declined' -> TO EVERY MEMBER
          //param1: firstName (receiver - student firstName), param1: company.name, param2: collaboration.title
          // existingMembers.forEach(function (member, index) {
          //   const templateData = {
          //     toEmail: member.members.email,
          //     firstName: member.members.firstName,
          //     param1: offer.companyDetails.companyName,
          //     param2: vm.collaboration.title,
          //     param3: vm.$route.params.id,
          //     template: "Student - Meeting request declined",
          //   };
          //   vm.$store.dispatch("sendMail", templateData);
          // });

          // const templateData = {
          //   toEmail: offer.user.email,
          //   firstName: offer.user.firstName,
          //   param1: vm.collaboration.title,
          //   template: "professional - Meeting request declined",
          // };
          // vm.$store.dispatch("sendMail", templateData);

          // var employees = offer.companyDetails.companyEmployees;
          // // sending email to each company employees
          // employees.forEach(function(memberId, index) {
          //     var ref = db.collection("users").doc(memberId);
          //     ref.get()
          //     .then(function(querySnapshot) {
          //         const collection = querySnapshot.data();
          //         // Mail logics
          //         const templateData = {
          //             toEmail: querySnapshot.data().email,
          //             firstName: querySnapshot.data().firstName,
          //             param1: vm.collaboration.title,
          //             template: 'professional - Collaboration declined by students',
          //         }
          //         vm.$store.dispatch('sendMail', templateData);
          //         // console.log({ "id":querySnapshot.id, "user": querySnapshot.data().email});
          //     })
          //     .catch(function(error) {
          //         console.log("Error getting documents: ", error);
          //     });
          // });
          // console.log(offer,vm.user,offer.companyDetails.companyEmployees);return false;
          // dispatch();
          // alert("-1");
        }
      } else if (status === 0) {
        if (confirm("Decline invitation?\nDo you wish to continue?")) {
          //TODO: MAIL 'Student - Meeting request declined' -> TO EVERY MEMBER
          //param1: firstName (receiver - student firstName), param1: company.name, param2: collaboration.title
          //
          // existingMembers.forEach(function (member, index) {
          //   const templateData = {
          //     toEmail: member.members.email,
          //     firstName: member.members.firstName,
          //     param2: offer.companyDetails.companyName,
          //     param1: vm.collaboration.title,
          //     template: "Student - Collaboration declined by company",
          //   };
          //   vm.$store.dispatch("sendMail", templateData);
          // });
          // console.log(offer,offer.email);
          // const templateData = {
          //   toEmail: offer.user.email,
          //   firstName: offer.user.firstName,
          //   param1: vm.collaboration.title,
          //   template: "professional - Collaboration declined by company",
          // };
          // vm.$store.dispatch("sendMail", templateData);
          // alert("0");
          // dispatch();
        }
      } else {
        // dispatch();
      }

      function dispatch() {
        // vm.$store.dispatch("updateCollaborationOfferStatus", data).then(() => {
        //   //settimeout is for getting the dates accepted by students.
        //   // setTimeout(()=>{
        //   vm.$store.dispatch(
        //     "getCollaborationOffersCollabId",
        //     vm.$route.params.id
        //   );
        //   // },1000)
        // });
      }
    },

    companyAcceptCollaboration: function (offer) {
      const thesisData = {
        subscription_id: this.subscriptionId,
        company_id: this.user.company,
        thesis_semester:
          this.collaboration && this.collaboration.targetSemester
            ? this.collaboration.targetSemester
            : "n/a",
      };
      var vm = this;
      var existingMembers = this.collaborationMembers;

      const data = {
        id: this.$route.params.id,
        newStatus: "collaborating",
        offerDetails: offer,
        members: this.$store.state.collaboration.collaborationMembers,
        matchPrice: this.matchPrice,
        acceptedBy: this.user.newUserId,
      };
      //create invoice!
      // this.$store.dispatch("updateCollaborationStatus", data).then(() => {
      //   //TODO: MAIL 'Student - Collaboration Confirmed by all' -> TO EVERY MEMBERS
      //   //param1: firstName (receiver - student firstName), param1: company.name, param2: collaboration.title, param3: offer.user.firstName (company user) param4: collaboration.targetSemester
      //! need to confirm if the api request needs to be made at this point or no, but for we'll do it here.
      this.$store.dispatch("setThesisSlots", thesisData);

      //   existingMembers.forEach(function (member, index) {
      //     const templateData = {
      //       toEmail: member.members.email,
      //       firstName: member.members.firstName,
      //       param1: offer.companyDetails.companyName,
      //       param2: vm.collaboration.title,
      //       param3: offer.user.firstName,
      //       param4: vm.collaboration && vm.collaboration.targetSemester ? vm.collaboration.targetSemester : 'n/a',
      //       param5: vm.$route.params.id,
      //       template: "Student - Collaboration Confirmed by all",
      //     };
      //     vm.$store.dispatch("sendMail", templateData);
      //   });

      //   //TODO: MAIL 'professional - Collaboration Confirmed'
      //   //TO COMPANY USER
      //   //param1: firstName (receiver - company firstName), param1: collaboration.title, param2: collaboration.targetSemester
      //   const templateData = {
      //     toEmail: offer.user.email,
      //     firstName: offer.user.firstName,
      //     param1: vm.collaboration.title,
      //     param2: vm.collaboration && vm.collaboration.targetSemester ? vm.collaboration.targetSemester : 'n/a',
      //     param3: offer.companyId,
      //     template: "professional - Collaboration Confirmed",
      //   };
      //   vm.$store.dispatch("sendMail", templateData);

      //   //TODO: Mail
      //   //TO EVERY NEGOTIATORS- OTHER THAN THUS USERS COMPANY...
      //   // @firstname, param1: collaboration.title
      //   // set status -2

      //   vm.changeOfferStatues(offer, 4);
      // });
    },
    //todo remove in MVP #1?
    // toggleFavorite: function(id) {
    //     var vm = this;
    //     if(vm.user.favorites.includes(id)) {
    //         vm.$store.dispatch('removeUserFavorite', id)
    //     } else {
    //         vm.$store.dispatch('addUserFavorite', id)
    //     }
    // },
    moment: function (seconds) {
      return moment.unix(seconds);
    },
    cutText: function (text, characters) {
      if (typeof text !== typeof undefined) {
        return text.length > characters
          ? text.substring(0, characters) + "..."
          : text;
      }
    },
    diff_weeks: function (dt2, dt1) {
      var diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
      diff /= 60 * 60 * 24 * 7;
      return Math.abs(Math.round(diff));
    },

    scan_div() {
      let elem = document.querySelector(".toggle-this");
      if (elem.clientHeight > this.data.collapsedHeight) {
        elem.style.height = this.data.collapsedHeight + "px";
        this.isReadMore = true;
      } else {
        this.isReadMore = false;
      }
    },
    gen_elem(data, cl) {
      return '<span class="' + cl + '">' + data + "</span>";
    },
    toggle() {
      let self = document.querySelector(".toggle-this");
      // let ht = self.clientHeight;
      if (this.isReadMore) {
        this.isReadLess = true;
        this.isReadMore = false;
        self.style.height = "auto";
      } else {
        this.isReadMore = true;
        this.isReadLess = false;
        self.style.height = this.data.collapsedHeight + "px";
      }
    },

    // dt1 = new Date(2014,10,2);
    // dt2 = new Date(2014,10,11);
    // console.log(diff_weeks(dt1, dt2));
    getThesisSlots: function () {
      // if(this.user)
      //todo
      // if (this.user.subscriptionPlan && this.user.subscriptionPlan.toLowerCase() == "beta") {
      //   this.thesisSlot = true;
      // } else {
      //   const data = {
      //     company: this.newUserCompany,
      //     semester: this.collaboration && this.collaboration.targetSemester ? this.collaboration.targetSemester : 'n/a',
      //     subscription: this.subscriptionId,
      //   };
      //   this.$store.dispatch("getThesisSlotsForSession", data).then((res) => {
      //     if (res === 0) {
      //       this.thesisSlot = false;
      //     } else if (res === 1) {
      //       this.thesisSlot = true;
      //     }
      //   });
      // }
    },
  },
  watch: {
    collaboration: function (val) {
      // if (typeof val.createdDate != "undefined") { // todo - part of the full implementation once Sumit is done
      this.loadPageMeta();
      // this.getThesisSlots();
      // }
      var self = this;
      setTimeout(function () {
        self.scan_div();
      }, 1000);
    },
  },
};
</script>

<style scoped>
.collaboration-thesis {
  padding: 1rem;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 4px;
  margin: 0;
}

.keywords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.keyword {
  list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 14px;
}

ul.desired-occupation {
  list-style: none;
  padding: 0;
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
}

ul.desired-occupation li {
  width: auto;
  margin-right: 0.6rem;
  border: 1px solid #0a7380;
  padding: 2px 6px;
  border-radius: 9px;
  margin-bottom: 6px;
  font-size: 14px;
  color: #0a7380;
}

.collaboration-action h6 {
  font-size: 18px;
}

.collaboration-action p {
  margin-top: 0.4rem;
  font-weight: 400;
}

.btn-action {
  background: #da518a;
  border-color: #da518a;
  padding: 0.4rem 2rem;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.btn-invited {
  padding: 0.4rem 2rem;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.erow-cta {
  border-radius: 4px;
  padding: 15px;
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}

.erow .offer-details {
  margin: 0 0 0 0;
}

.offer-steps ul li {
  font-size: 12px;
}

@media (max-width: 800px) {
  .offer-steps ul li {
    font-size: 0.5rem;
  }

  .erow .offer-details {
    padding: 5px;
  }
}

.erow-cta h4 {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 2px;
}

.toggle-this {
  overflow: hidden;
  position: relative;
}

.toggler {
  text-align: center;
  padding: 1rem 0 0 0;
  cursor: pointer;
}

.toggler .readMore {
  width: 100%;
  position: absolute;
  bottom: 0px;
  right: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
  padding: 155px 40px 0px;
}

.widget-header h4 {
  font-weight: 600;
  color: #000;
}

.request-interview h6 {
  margin-top: 0px;
}

.request-interview h5 {
  font-size: 16px;
  color: black;
  font-weight: 400;
}

.image-container img {
  width: 200px;
  align-self: center;
}

.negotations-company-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: #000;
}

.negotations-company-title {
  font-size: 28px;
  font-weight: 600;
  color: #000;
  padding-bottom: -2px;
  margin: 0;
}

.company-industry-section h4 {
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
}

.company-industry-section p {
  font-size: 12px;
  font-weight: 400;
}

.header-margin {
  margin: 12px 0 3px 0;
}

.company-description h6 {
  font-size: 14px;
  font-weight: 700;
}

.company-description p {
  font-size: 14px;
  font-weight: 400;
}

.negotations-company-address {
  font-size: 12px;
  color: #000;
}

.negotations-buttons {
  align-self: center;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.negotations-status {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background-color: #fbfbfb;
  padding: 10px 13px;
  margin: 0 15px;
  width: 100%;
}

.negotations-status-student .confirmed-status {
  color: #fbfbfb;
}

.confirmed-status {
  color: #000;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
}

.negotations-status-student {
  border: 1px solid #d22d89;
  background-color: #d22d89;
  border-radius: 4px;
  padding: 10px 13px;
  margin: 0 15px;
  width: 100%;
}

.negotations-status-student .negotations-status-title,
.negotations-status-student .negotations-status-sub-title {
  color: white;
  font-size: 12px;
}

.negotations-status-student i {
  color: white;
  font-size: 30px;
  opacity: 0.8;
}

.negotations-status-title {
  font-weight: 700;
  color: #000;
}

.negotations-status-sub-title {
  font-size: 12px;
  color: #000;
}

.negotations-request {
  border: 1px solid #f1f1f1;
  padding: 24px 5px;
  margin: 12px 0 0;
  display: flex;
  width: 100%;
}

.negotations-request-title {
  font-weight: 700;
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;
}

.negotations-request-sub-title {
  font-size: 12px;
  margin-top: 3px;
  color: #000;
}

.offer-steps {
  padding: 0;
}

.pink-bg {
  background-color: #d22d89;
}

.grey-bg {
  background-color: #fbfbfb !important;
  border: 1px solid #e1e1e1 !important;
}

.no-thesis-banner {
  background-color: #ffc561;
  width: 100%;
  height: 64px;
  font-size: 12px;
  bottom: 100;
}

.top-margin {
  margin-top: 55px;
}

.bg-yellow {
  background-color: #ffc561;
}
</style>