<template>
  <div class="widget mb-3">
    <div class="widget-splash splash-line"></div>
    <div class="widget-header sticky">
      <h4>Topic</h4>
      <div class="widget-navigation">
        <!-- step 4 -->
        <router-link
          :to="'/collaboration/edit/' + this.$route.params.id"
          class="widget-nav"
          v-if="isUserOwner && isEditable"
          ><i class="fas fa-pen"></i> Edit</router-link
        >
      </div>
    </div>
    <div class="widget-body">
      <div class="collaboration-thesis">
        <h1>{{ collaboration.title }}</h1>
        <h2 class="pt-2">{{ collaboration.subtitle }}</h2>
        <p v-if="collaboration.keywords" class="keywords pt-2">
          <span
            class="keyword"
            v-for="(keyword, index) in collaboration.keywords"
            :key="index"
            >{{ keyword }}</span
          >
        </p>
        <p
          style="
            display: inline-block;
            vertical-align: middle;
            padding: 6px 10px;
            font-size: 16px;
            font-weight: 400;
            color: #fff;
            text-transform: uppercase;
            border-radius: 4px;
            margin-right: 10px;
          "
          :style="{ backgroundColor: collaboration.researchAreaData ? collaboration.researchAreaData.hexCode : '' }"
        >
          {{ collaboration.researchAreaData ? collaboration.researchAreaData.categoryName : ''}}
        </p>
        <p
          v-for="category in collaboration.relatedAreas"
          :key="category.id"
          style="
            display: inline-block;
            vertical-align: middle;
            padding: 6px 10px;
            font-size: 16px;
            font-weight: 400;
            color: #fff;
            text-transform: uppercase;
            margin-right: 10px;
            border-radius: 4px;
          "
          :style="{ backgroundColor: category ? category.hexCode :''}"
        >
          {{ category.categoryName }}
        </p>
        <div class="collaboration-body toggle-this">
          <template v-if="collaboration.content">
            <h6>Thesis project description</h6>
            <div
              class="collaboration-thesis-body"
              v-html="collaboration.content"
            ></div>
          </template>
          <!-- No longer showing methodology -->
          <!-- <template v-if="collaboration.methodology">
                      <h6>Methodology</h6>
                      <div
                        class="collaboration-thesis-body"
                        v-html="collaboration.methodology"
                      ></div>
                    </template> -->
          <template v-if="collaboration.value">
            <h6>Managerial value of the research</h6>
            <div
              class="collaboration-thesis-body"
              v-html="collaboration.value"
            ></div>
          </template>
          <template v-if="collaboration.motivation">
            <h6>Research topic motivation</h6>
            <div
              class="collaboration-thesis-body"
              v-html="collaboration.motivation"
            ></div>
          </template>
          <template v-if="collaboration.whyus">
            <h6>Why collaborate with us</h6>
            <div
              class="collaboration-thesis-body"
              v-html="collaboration.whyus"
            ></div>
          </template>

          <div class="toggler" :key="2" v-show="isReadMore">
            <span class="readMore" @click="toggle">Load More</span>
          </div>
          <div class="toggler" :key="3" v-show="isReadLess">
            <span class="readLess" @click="toggle">Load Less</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "isUserOwner",
    "isEditable",
    "collaboration",
    "isReadMore",
    "isReadLess",
    "toggle",
  ],
};
</script>

<style>
.widget-header h4 {
  font-weight: 600;
  color: #000;
}

.collaboration-thesis {
  padding: 1rem;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 4px;
  margin: 0;
}

.keywords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.keyword {
  list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 14px;
}

.toggle-this {
  overflow: hidden;
  position: relative;
}

.toggler {
  text-align: center;
  padding: 1rem 0 0 0;
  cursor: pointer;
}

.toggler .readMore {
  width: 100%;
  position: absolute;
  bottom: 0px;
  right: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
  padding: 155px 40px 0px;
}
</style>