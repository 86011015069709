<template>
 <div class="">
  <div v-if="isUserOwner && collaborationStatus !== 'collaborating'">
   <div class="erow-cta mb-2" v-if="collaborationStatus === 'published'">
    <h4>Published</h4>
    Your collaboration is now published and available for interview
    requests.
   </div>

   <div class="erow-cta mb-2" v-if="collaborationStatus == 'review'">
    <h4>Your thesis proposal is in review</h4>
    We’ve received your thesis proposal and we are reviewing it as 
    fast as we can. You’ll hear from us when your thesis proposal is live! 🚀
   </div>
   <div class="erow-cta mb-2" v-else-if="collaborationStatus === 'approved'">
    <h4>READY TO BE PUBLISHED</h4>
    Your thesis topic is not published and therefore not visible to
    companies.
   </div>
  </div>
  <div v-if="isUserOwner && collaborationStatus === 'collaborating'">
   <div class="erow-cta mb-2">
    <h4>Collaboration Confirmed!</h4>
    <h6 class="pt-2">
     Congratulations. Your collaboration has been confirmed by the
     company.
    </h6>
   </div>
  </div>
  <div v-if="isUserOffering && collaborationStatus === 'collaborating'">
   <div class="erow-cta mb-2">
    <h4>Congratulations!</h4>
    <h6 class="pt-2">
     Collaboration confirmed with the student group.
    </h6>
   </div>
  </div>
 </div>
</template>

<script>
export default {
 props: ['collaborationStatus', 'isUserOwner', 'isUserOffering',]

}
</script>

<style></style>