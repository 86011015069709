<template>
 <div class="">
 <div v-if="isUserOwner" class="mb-1">
  <router-link to="/collaboration/my" class="btn btn-sm btn-link"><i class="fas fa-angle-left"></i> My Collaborations
  </router-link>
 </div>

 <div v-else class="mb-1">
  <router-link to="/collaborations" class="btn btn-sm btn-link"><i class="fas fa-angle-left"></i> Find
   collaborations</router-link>
 </div>
</div>
</template>

<script>
export default {
 props: ['isUserOwner']

}
</script>

<style></style>